// Defaults
window.app_defaults = {
    map: (typeof(google) !== "undefined") ? {
      map: {
        fullscreenControl: true,
        mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.TERRAIN, google.maps.MapTypeId.HYBRID]
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scaleControl: true
      },
      polygon: {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#FF0000',
        fillOpacity: 0.25,
        clickable: false
      },
      polyline: {
        icons: [{
          icon: {
            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
          },
          repeat: '250px'
        }],
        strokeColor: "Blue",
        strokeWeight: 3,
        clickable: false
      },
      polyline_dash: {
        icons: [{
          icon: {
            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
          },
          repeat: '250px'
        }, {
          icon: {
            path: 'M 0,-1 0,1'
          },
          offset: '0',
          repeat: '10px'
        }],
        strokeColor: "Blue",
        strokeWeight: 3,
        clickable: false
      },
    } : {}
  };