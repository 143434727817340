window.app_timeouts = [];
window.app_intervals = [];
window.app_xhrs = [];

// Moment.js
moment.locale(window.language.short);

// Bootstrap table
var bt_export_filename = "-";
Object.assign($.BootstrapTable.DEFAULTS, {
  onPreBody() {
    $("[data-toggle='popover']").popover('hide');
  },
  onPostBody() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $('.hierarchy-button').on('shown.bs.popover', function () {
      $(".add-to-company-filter").click(function() {
        $(".bootstrap-table-filter-control-company").val($(this).html());
        $("[data-toggle='popover']").popover('hide');
        triggerBTSearch($(".bootstrap-table-filter-control-company"));
        return false;
      });
    });
  },
  theadClasses: "thead-dark",
  locale: window.language.full,
  pageSize: 25,
  search: true,
  showColumns: true,
  showToggle: true,
  filterControl: true,
  showSearchClearButton: true,
  visibleSearch: true,
  mobileResponsive: true,
  exportTypes: ['xlsx'],
  exportOptions: {
    csvSeparator: ';',
    fileName() {return bt_export_filename},
    mso: {xslx: {formatId: {numbers() {return '@'}}}},
    onCellData($cell, row, col, cellText, cellType) {
      if($cell.get(0).tagName == 'TH')
        return $cell.find("div.th-inner").text();
      plain_text = $cell.text();
      return (plain_text == "-") ? "" : plain_text;
    }
  }
});

Object.assign($.BootstrapTable.COLUMN_DEFAULTS, {
  filterDatepickerOptions: {
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true
  }
});
$.fn.datepicker = $.fn.daterangepicker;

$.fn.bootstrapTable.methods.push('onColumnSearch');
function triggerBTSearch(filter_input_object) {
  let event = new Event("keyup");
  event.target = filter_input_object;
  Object.defineProperty(event, 'currentTarget', {writable: false, value: filter_input_object});
  $(filter_input_object).closest("table").bootstrapTable("onColumnSearch", event);
}

function fieldFormatter(value, row, index, field) {
  var field_formatted = field + "_formatted";
  return (row[field_formatted] !== null) ? row[field_formatted] : row[field];
}
window.fieldFormatter = fieldFormatter;

function dateSorter(a, b) {
  try {
    let a_date = moment(a, window.datetime_format);
    let b_date = moment(b, window.datetime_format);
    if (a_date < b_date) return -1;
    if (a_date > b_date) return 1;
  } catch(error) {};
  return 0;
}
window.dateSorter = dateSorter;

function buttons() {
  return {
    btnDestroy: {
      name: 'btnDestroy',
      text: window.strings.destroy,
      icon: 'fa-trash-alt',
      event() {
        let $el = this.$el;
        let ids = $el.bootstrapTable('getSelections').map((s) => s.id);
        let url = $el.data("buttons-url").btnDestroy.url;
        if(ids && url) {
          dataConfirmModal.confirm({
            text: $el.data("buttons-url").btnDestroy.confirm,
            onConfirm() {
              $.ajax({
                url: url,
                data: {ids: ids},
                type: 'DELETE',
                success() {
                  $el.bootstrapTable('refresh');
                }
              });
            }
          });
        }
        return false;
      },
      attributes: {
        'data-toggle': 'tooltip',
        title: window.strings.destroy_selected,
        style: "display: none"
      }
    }
  }
}
window.buttons = buttons;

// CSFR Token
$(document).on('ajax:complete', (event) =>  {
	let [xhr, status]  = event.detail
	header_token = xhr.getResponseHeader('X-CSRF-Token');
	if (header_token) {
		$('meta[name=csrf-token]').attr('content', header_token)
		$('input[name=authenticity_token]').val(header_token)
	}
});

// Bootstrap: show/hide, enable/disable
$.fn.showBT = function() {
  return this.each(function() {
    if($(this).hasClass("d-none"))
      $(this).removeClass("d-none");
    if($(this).is("input,select"))
      $(this).enableBT().parent(".form-group,.custom-control").showBT();
  });
};
$.fn.hideBT = function() {
  return this.each(function() {
    if(!$(this).hasClass("d-none"))
      $(this).addClass("d-none");
    if($(this).is("input,select"))
      $(this).disableBT().parent(".form-group,.custom-control").hideBT();
  });
};
$.fn.toggleBT = function(show) {
  return show ? this.showBT() : this.hideBT();
};
$.fn.enableBT = function() {
  return this.each(function() {
    if($(this).hasClass("disabled"))
      $(this).removeClass("disabled");
    if($(this).is("input,select"))
      $(this).prop("disabled", false);
  });
};
$.fn.disableBT = function() {
  return this.each(function() {
    if(!$(this).hasClass("disabled"))
      $(this).addClass("disabled");
    if($(this).is("input,select"))
      $(this).prop("disabled", true);
    });
};

// Minicolors
Object.assign($.minicolors.defaults, {
  theme: 'bootstrap'
});

$(document).on('turbolinks:load', () => {	//turbolinks
//$(document).ready(function() {

    let body_data = $('body').data();

    // CSFR Token
    $.ajaxSetup({
			beforeSend: (xhr) => {
				xhr.setRequestHeader('X-CSRF-Token', $('meta[name=csrf-token]').attr('content'));
			}
		});

    // Modal
    dataConfirmModal.setDefaults({
      title: window.strings.confirm.title,
      commit: window.strings.confirm.commit,
      cancel: window.strings.confirm.cancel,
      cancelClass: 'btn-secondary'
    })

    // Popover
    $('[data-toggle="popover"]').popover();
    $('.popover-dismiss').popover({
      trigger: 'focus'
    })

    // Tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // Daterangepicker
    $('[data-toggle="daterangepicker"]').daterangepicker({
        locale: {
            format: window.date_format
        },
        format: window.date_format,
      ranges: {
        [window.strings.daterangepicker.ranges.today]: [moment(), moment()],
        [window.strings.daterangepicker.ranges.yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        [window.strings.daterangepicker.ranges.last_7_days]: [moment().subtract(6, 'days'), moment()],
        [window.strings.daterangepicker.ranges.last_30_days]: [moment().subtract(29, 'days'), moment()],
        [window.strings.daterangepicker.ranges.this_month]: [moment().startOf('month'), moment().endOf('month')],
        [window.strings.daterangepicker.ranges.last_month]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      isCustomDate(date) {
        let days = $(this.element).data("days");
        if(Array.isArray(days) && days.includes(date.format("YYYY-MM-DD")))
          return "text-warning font-weight-bolder";
      }
    });
    $('[data-toggle="datetimerangepicker"]').daterangepicker({
        locale: {
            format: window.date_format
        },
        format: window.date_format,
      timePicker: true
    });
    $('[data-toggle="datepicker"]').daterangepicker({
        locale: {
            format: window.date_format
        },
        format: window.date_format,
      singleDatePicker: true
    });
    $('[data-toggle="datetimepicker"]').daterangepicker({
        locale: {
            format: window.date_format
        },
        singleDatePicker: true,
      format: window.date_format,
      timePicker: true
    });

    // Day slider
    $("[data-day-slide]").click(function() {
      let $input_group =  $(this).closest(".input-group");
      let $input =  $input_group.children("input[name='day_slide']");
      let val_date = moment($input.val(), window.date_format);
      if(val_date.isValid()) {
        $(this).siblings(".fa-spinner").removeClass("d-none");
        $input_group.find("[data-day-slide]").hide();
        $input.prop( "disabled", true);
        $input.data("current-day-slide", $(this).data("day-slide"));
        switch($(this).data("day-slide")) {
          case 'before':
            $input.val(val_date.subtract(1, 'days').format(window.date_format)).change();
            break;
          case 'after':
            $input.val(val_date.add(1, 'days').format(window.date_format)).change();
            break;
        }
      }
      return false;
    });
    $("input[name='day_slide']").on("day-slide-success", function() {
      let $input = $(this);
      let $input_group =  $input.closest(".input-group");
      $input_group.find(".fa-spinner:not(.d-none)").addClass("d-none");
      $input_group.find("[data-day-slide]").show();
      $input.prop("disabled", false);
      if($input_group.find("input[name='day_slide_hook']").is(':checked')) {
        $("input[name='day_slide']").each(function() {
          if($(this).parent().find("input[name='day_slide_hook']").is(':checked') && $(this).val() != $input.val()) {
            $(this).removeData("current-day-slide");
            $(this).val($input.val()).change();
          }
        });
      }
    });

    /* Bootstrap table */
    $('[data-toggle="table"]').each(function() {  //per turbolinks
      if(!$(this).closest(".bootstrap-table").length)
        $(this).bootstrapTable();
    });
    $("div.export button").click(function() {
      bt_export_filename = $(this).closest(".bootstrap-table").find("[data-export-filename]").data("export-filename");
    });
    $('[data-toggle="table"]').on('check.bs.table uncheck.bs.table check-all.bs.table uncheck-all.bs.table check-some.bs.table uncheck-some.bs.table', function() {
      for(const button in $(this).data('buttons-url'))
        $(this).closest(".bootstrap-table").find(`[name=${button}]`).toggle($(this).bootstrapTable('getSelections').length>0);
    });
    $('[data-toggle="table"]').on('apply.daterangepicker', 'input.date-filter-control', function(e, picker) {
      $(this).val(picker.startDate.format(window.date_format));
      triggerBTSearch(e.target);
    });

    /* gijgo datepicker per Bootstrap table
    $('th.gijgo_datepicker input[type=text]').each(function() {
    	$(this).datepicker({
        locale: window.language.full.toLowerCase(),
        uiLibrary: "bootstrap4",
        showRightIcon: false,
        format: window.date_format,
    		change: (e) => {
          triggerBTSearch(e.target);
    		}
    	});
    });
    */

    /* Menu */
    (function initMenu() {
      $('#menu ul').add('#menu-mobile ul').hide();
      $('#menu ul').add('#menu-mobile ul').children('.current').parent().show();
      //$('#menu ul:first').show();
      $('#menu li a').add('#menu-mobile li a').click(function() {
          var checkElement = $(this).next();
          if((checkElement.is('ul')) && (checkElement.is(':visible'))) {
              return false;
          }
          if((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
              $('#menu ul:visible').add('#menu-mobile ul:visible').slideUp('normal');
              checkElement.slideDown('normal');
              return false;
          }
      });
      //Gestione della sidebar a schermo ridotto
      $("#sidebar-mobile-toggle").click(function(){
        $("#sidebar-wrapper-mobile").show().animate({width: '250px'});
        $("#glass-layer").show().animate({opacity: '0.7'});
        $(".blurrable").addClass("blur");
        return false;
      });
      $("#sidebar-mobile-close").click(function(){
        $("#sidebar-wrapper-mobile").show().animate({width: '0px'});
        $("#glass-layer").animate({opacity: '0'}).hide();
        $(".blurrable").removeClass("blur");
        $('#menu-mobile ul:visible').slideUp('normal');
        return false;
      });
      $("#glass-layer").click(function(){
        $("#sidebar-wrapper-mobile").show().animate({width: '0px'});
        $("#glass-layer").animate({opacity: '0'}).hide();
        $(".blurrable").removeClass("blur");
        $('#menu-mobile ul:visible').slideUp('normal');
      });
    })();

    // Selezione SideBar
    let controller = $("body").attr('data-controller').split(" ")[0];
    let action = $("body").attr('data-action').split(" ")[0];
    if($.inArray(controller, ["alarms"])>=0)
        $(".nav-item[data-menu='alarms']").addClass("active"); //$("#menu-alarms").addClass("active");
    else if($.inArray(controller, ["companies"])>=0)
        $(".nav-item[data-menu='companies']").addClass("active"); //$("#menu-companies").addClass("active");
    else if($.inArray(controller, ["users"])>=0)
        $(".nav-item[data-menu='users']").addClass("active"); //$("#menu-users").addClass("active");
    else if($.inArray(controller, ["machines", "machine_models", "machine_groups", "settings"])>=0)
        if($.inArray(action, ["activities"])>=0)
          $(".nav-item[data-menu='activities']").addClass("active"); //$("#menu-activities").addClass("active");
        else
          $(".nav-item[data-menu='machines']").addClass("active"); //$("#menu-machines").addClass("active");
    else if($.inArray(controller, ["activities"])>=0)
      $(".nav-item[data-menu='activities']").addClass("active");
    else if($.inArray(controller, ["devices", "sensor_settings"])>=0)
        $(".nav-item[data-menu='devices']").addClass("active"); //$("#menu-devices").addClass("active");
    else if($.inArray(controller, ["geofences"])>=0)
        $(".nav-item[data-menu='geofences']").addClass("active"); //$("#menu-geofences").addClass("active");
    else
        $(".nav-item[data-menu='home']").addClass("active"); //$("#menu-home").addClass("active");
    // chiudo l'eventuale sezione aperta in caso di chiusura della sidebar
    $('#sidebar-wrapper').mouseleave(function () {
      $('#menu ul:visible').slideUp('normal');
    });
    // riposizionamento dinamico della sidebar durante lo scroll
    $(window).scroll(function() {
      scroll_offset = 0;
      if (window.scrollY < 100)
          scroll_offset = 100 - window.scrollY;
      $("#sidebar-wrapper").css('top', scroll_offset);
    });

    // Eventi
    $(window).trigger(body_data.controller + ':loaded')
    $(window).trigger(body_data.controller + '#' + body_data.action + ':loaded')
});

// Cancellazione turbolinks
document.addEventListener("turbolinks:before-cache", () => {
  $('[data-toggle="tooltip"]').tooltip('hide');
  $('[data-toggle="popover"]').popover('dispose');
  $('[data-toggle="table"]').bootstrapTable('destroy');
  if($('[data-tree-url]')['tree']) {
    try {
      $('[data-tree-url]').tree().destroy();
      $("#show_company").empty();
    } catch(error) {}
  }
  while(window.app_timeouts.length) {
    try {
      clearTimeout(window.app_timeouts.pop());
    } catch(error) {}
  }
  while(window.app_intervals.length) {
    try {
      clearInterval(window.app_intervals.pop());
    } catch(error) {}
  }
  while(window.app_xhrs.length) {
    try {
      window.app_xhrs.pop().abort();
    } catch(error) {}
  }
});
